import 'element-plus/dist/index.css';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import './styles/global.css';
import './styles/reset.css';
import './styles/scrollbar.css';
import './styles/splitter.css';

import App from './App.vue';
import { router } from './routers';

const app = createApp(App);
const store = createPinia();
store.use(piniaPluginPersistedstate);

app.use(store).use(router).mount('#app');
