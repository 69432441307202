import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'base',
    children: [
      {
        path: 'chat',
        component: () => import('@/views/chat/Index.vue'),
      },
      {
        path: 'ai-tool',
        name: 'ai-tool',
        children: [
          {
            path: '',
            name: 'ai-tool-list',
            component: () => import('@/views/ai-tool/Tool.vue'),
          },
          {
            path: ':name',
            name: 'ai-tool-page',
            component: () => import('@/views/ai-tool/Page.vue'),
          },
        ],
      },
      {
        path: 'ai-app',
        name: 'ai-app',
        component: () => import('@/views/ai-app/Index.vue'),
      },
      {
        path: 'ai-test',
        name: 'ai-test',
        component: () => import('@/views/ai-test/Index.vue'),
      },
    ],
    component: () => import('@/views/Base.vue'),
    alias: ['/home'],
  },
  {
    path: '/submission',
    name: 'submission',
    component: () => import('@/views/submission/Index.vue'),
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/NotFound.vue') },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
